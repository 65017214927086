import React from 'react';
import './BlogNews.css'
import news from '../data/news.js';
import BlogNewsArticle from './BlogNewsArticle.js'


class BlogNews extends React.Component{

    render(){

        return(

            <div id="blogNewsMain">
                <div id = "blogNewsWrapper">

                    {news.map( (news, index) => (

                        <BlogNewsArticle id={"newsArticleId" + news.id} key={"blogNewsArticle" + news.id + news.date}
                             id = {news.id}
                             title = {news.title}
                            date = {news.date}
                            text = {news.text}
                            images = {news.images}
                        />

                    ))}

                </div>

            </div>

        )


    }

}

export default BlogNews;
