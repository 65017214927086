import React from 'react';
import SlideshowCustom from "../components/SlideshowCustom";
import Header from "../components/Header";
import SliderPictureSimple from "../components/SliderPictureSimple";
import Footer from "../components/Footer";
import GoUpFooter from "../components/GoUpFooter";


class Games extends React.Component{

    renderHeader(){

        return(
            <Header
                info={[
                    ["HOME", false, "/"],
                    ["CV", false, "/cv"],
                    ["RENDERS", false, "/renders"],
                    ["BLOG", false, "/blog"],
                    ["PROJECTS", false, "/projects"],
                    ["GAMES", true, "/games"],
                    ["CONTACT", false, "/contact"]
                ]}
            />
        )

    }

    renderCustomSlideshow(name, text, image, number, link, linkText){

        return(
            <SlideshowCustom
                name = {name}
                text = {text}
                image = {image}
                number = {number}
                link = {link}
                linkText = {linkText}
            />
        )

    }

    render(){

        return(

            <div>
                {this.renderHeader()}
                <SliderPictureSimple title = {"GAMES"} image = {"games.png"}/>
                <div className="slideEmpty"/>
                {this.renderCustomSlideshow(
                    "UPCOMING PSX-STYLE FPS GAME",
                    "An under-development videogame created with Unity and 3DSMax, whose main feature is its old school graphics, which utilize a PS1 shader in order to create low resolution textures that are good looking. The game itself is a FPS, where you take control of a magician that has to venture into dangerous and dark ruins. Each hand has the ability to cast a magic spell.",
                    "old",
                    6,
                    "https://www.youtube.com/watch?v=cgRZE9Wy9V8",
                    "CHECK A GRAPHICS DEMO"
                )}
                <div className="slideEmpty"/>
                {this.renderCustomSlideshow(
                    "ARCADE GAME",
                    "A 4th year project, where I created a 2D Unity game to integrate it with an homemade arcade machine. The game is a 2D platformer, where you have the choice to play singeplayer or cooperative. The game features different weapons, levels and enemies.",
                    "2d",
                    11,
                    "https://www.youtube.com/watch?v=Qqds5Lz1u1I",
                    "CHECK OUT THE TRAILER"
                )}
                <div className="slideEmpty"/>
                <GoUpFooter/>
                <Footer/>
            </div>

        )

    }

}

export default Games;
