import React from 'react';
import "./SliderPicture.css";
import "./CoolButton.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faYoutube, faGithub } from '@fortawesome/free-brands-svg-icons'
import {Link} from "react-router-dom";

const images = [
    "main2.png",
    "main3.png",
    "main4.png",
];


class SliderPicture extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            imgPathF: images[0].toString(),
            imgPathB: images[1].toString(),
            index: 1,
        };
    }

    componentDidMount = () => {

        this.interval = setInterval(() => {

            document.querySelector("#sliderPictureImageFront").classList.add('smoothTransition');

            setTimeout(() => {

                if(this.state.index + 1 >= images.length){

                    this.setState({
                        index: 0,
                        imgPathF: images[images.length - 1].toString(),
                    })

                }else {

                    this.setState({
                        index: (this.state.index + 1),
                        imgPathF: images[this.state.index].toString(),
                    })
                }

                document.querySelector("#sliderPictureImageFront").classList.remove('smoothTransition');

                setTimeout(() => {

                    this.setState({
                        imgPathB: images[this.state.index].toString(),
                    })

                }, 2000)

            }, 2000)

        }, 4000);

    }

    componentWillUnmount =() => {

        clearInterval(this.interval);

    }

    render(){

        return(
            <div id="mainSliderPicture">
                <img id="sliderPictureImage" src={this.state.imgPathB}/>
                <img id="sliderPictureImageFront" src={this.state.imgPathF}/>
                <div id="sliderPictureWrapper">
                    <div id="sliderPicturePresentation">
                        <h1 id="header1">Alex Valle</h1>
                        <h2 id="header2">Multimedia Engineer</h2>
                        <p id="p1">Welcome to my personal page! My name is Alex and I'm a multimedia engineering graduate. I love anything to do with programming, modelling and designing.
                            Here you can view my latest works, check out some old projects, download my CV and contact me through a form! Enjoy!</p>
                        <div>
                            <Link to={"/cv"} className="link">
                                <div className="coolButtonWhite">
                                    MY CV
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div id="sliderPictureSociales">
                        <div className="buttonSociales">
                            <a href="https://www.linkedin.com/in/alexvalletavira/" target="_blank">
                                <div className="buttonSociales2">
                                    <FontAwesomeIcon className="sliderIcono" icon={faLinkedinIn} />
                                </div>
                            </a>
                        </div>
                        <div className="buttonSociales">
                            <a href="https://github.com/alexvalletavira" target="_blank">
                                <div className="buttonSociales2">
                                    <FontAwesomeIcon className="sliderIcono" icon={faGithub} />
                                </div>
                            </a>
                        </div>
                        <div className="buttonSociales">
                            <a href="https://www.youtube.com/channel/UCg9MhUz9W1Np-283i5uxx3g/featured" target="_blank">
                                <div className="buttonSociales2">
                                    <FontAwesomeIcon className="sliderIcono" icon={faYoutube} />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )

    }


}

export default SliderPicture;
