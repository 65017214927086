import React from 'react';
import './Header.css';
import HeaderButton from "./HeaderButton";

class Header extends React.Component {

    componentDidMount() {

        document.querySelector("#smallMenuOpened").classList.add('closed');

    }

    openTheMenu(){

        document.querySelector("#smallMenuOpened").classList.remove('closed');
        document.querySelector("#smallMenuOpened").classList.add('opened');

    }

    closeTheMenu(){

        document.querySelector("#smallMenuOpened").classList.add('closed');
        document.querySelector("#smallMenuOpened").classList.remove('opened');

    }

    renderHeaderButton(text, selected, link){

        return(
            <HeaderButton
                text={text}
                selected={selected}
                link={link}
            />

        )

    }

    render(){

        return(
            <div id="headerMain">
                <div id="wrapperHeader">
                    <div className="logoContainer">
                        <img id="logo" src={"nuevologo2.png"}/>
                    </div>

                    <div id="midContainer"/>

                    <div id="buttonContainer">
                        {this.renderHeaderButton(this.props.info[0][0], this.props.info[0][1], this.props.info[0][2])}
                        {this.renderHeaderButton(this.props.info[1][0], this.props.info[1][1], this.props.info[1][2])}
                        {this.renderHeaderButton(this.props.info[2][0], this.props.info[2][1], this.props.info[2][2])}
                        {this.renderHeaderButton(this.props.info[3][0], this.props.info[3][1], this.props.info[3][2])}
                        {this.renderHeaderButton(this.props.info[4][0], this.props.info[4][1], this.props.info[4][2])}
                        {this.renderHeaderButton(this.props.info[5][0], this.props.info[5][1], this.props.info[5][2])}
                        {this.renderHeaderButton(this.props.info[6][0], this.props.info[6][1], this.props.info[6][2])}
                    </div>

                    <div id="smallMenu" className="logoContainer" onClick={this.openTheMenu}>
                        <img id="logo2" className="logoSmallMenu" src={"smallMenu.png"} />
                    </div>

                </div>

                <div id="smallMenuOpened" className="openClose">
                    <div id="closeTheSmallMenu" className="openClose" onClick={this.closeTheMenu}>
                        <img id="logo3" src={"smallMenuClose.png"}/>
                    </div>
                    <div id="smallMenuWrapper" className="openClose">
                        {this.renderHeaderButton(this.props.info[0][0], this.props.info[0][1], this.props.info[0][2])}
                        {this.renderHeaderButton(this.props.info[1][0], this.props.info[1][1], this.props.info[1][2])}
                        {this.renderHeaderButton(this.props.info[2][0], this.props.info[2][1], this.props.info[2][2])}
                        {this.renderHeaderButton(this.props.info[3][0], this.props.info[3][1], this.props.info[3][2])}
                        {this.renderHeaderButton(this.props.info[4][0], this.props.info[4][1], this.props.info[4][2])}
                        {this.renderHeaderButton(this.props.info[5][0], this.props.info[5][1], this.props.info[5][2])}
                        {this.renderHeaderButton(this.props.info[6][0], this.props.info[6][1], this.props.info[6][2])}
                    </div>

                </div>
            </div>
        )

    }

}

export default Header;
