import React from 'react';
import Header from "../components/Header";
import SliderPicture from "../components/SliderPicture";
import NewsRecap from "../components/NewsRecap";
import SnapshotMainPage from "../components/SnapshotMainPage";
import Footer from "../components/Footer";
import GoUpFooter from "../components/GoUpFooter";

class MainPage extends React.Component{

    renderHeader(){

        return(
            <Header
                info={[
                    ["HOME", true, "/"],
                    ["CV", false, "/cv"],
                    ["RENDERS", false, "/renders"],
                    ["BLOG", false, "/blog"],
                    ["PROJECTS", false, "/projects"],
                    ["GAMES", false, "/games"],
                    ["CONTACT", false, "/contact"]
                ]}
            />
        )

    }

    renderSnapshot(header, text, button, image, link){

        return(
            <SnapshotMainPage
                header = {header}
                text = {text}
                button = {button}
                image = {image}
                link = {link}
            />
        )

    }

    render(){

        return(
            <div>
                {this.renderHeader()}
                <SliderPicture/>
                <NewsRecap/>
                {this.renderSnapshot("PROJECTS", "Some projects I've been doing lately.", "CHECK THEM OUT", "renders/inverted.png", "/projects" )}
                {this.renderSnapshot("GAMES", "An assorment of games I've developed through the years.", "CHECK THEM OUT", "renders/inverted2.png", "/games") }
                {this.renderSnapshot("RENDERS", "Some 3D scenes I have created and renderized.", "CHECK THEM OUT", "renders/inverted3.png", "/renders") }
                <div className="slideEmpty"/>
                <GoUpFooter/>
                <Footer/>
            </div>

        )
    }
}


export default MainPage;
