import React from 'react';
import Header from "../components/Header";
import SliderPictureSimple from "../components/SliderPictureSimple";
import BlogNews from "../components/BlogNews";
import Footer from "../components/Footer";
import GoUpFooter from "../components/GoUpFooter";

class Blog extends React.Component{

    renderHeader(){

        return(
            <Header
                info={[
                    ["HOME", false, "/"],
                    ["CV", false, "/cv"],
                    ["RENDERS", false, "/renders"],
                    ["BLOG", true, "/blog"],
                    ["PROJECTS", false, "/projects"],
                    ["GAMES", false, "/games"],
                    ["CONTACT", false, "/contact"]
                ]}
            />
        )

    }

    render(){
        return(
            <div>
                {this.renderHeader()}
                <SliderPictureSimple title = {"BLOG"} image = {"blog.png"}/>
                <div className="slideEmpty"/>
                <BlogNews/>
                <GoUpFooter/>
                <Footer/>
            </div>
        )
    }

}

export default Blog;
