import React from 'react';
import {Link} from "react-router-dom";
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import './MenuOpenedCV.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class MenuOpenedCV extends React.Component{

    closeMenu(){
        document.querySelector("#menuOpenedCVMain").classList.add('closed');
    }

    render(){

        return(

            <div id="menuOpenedCVMain">
                <div id="menuOpenedCVMainCloseMenuWrapper">
                    <FontAwesomeIcon id="menuOpenedCVMainCloseMenuIcon" icon={faXmark}  onClick={this.closeMenu}/>
                </div>
                <div id="menuOpenedCVOptionsWrapper">
                    <Link to={"/"} className="menuOpenedCVOptionsLink"> HOME </Link>
                    <div className="menuOpenedCVOptionsDivisor"/>
                    <Link to={"/renders"} className="menuOpenedCVOptionsLink"> RENDERS </Link>
                    <div className="menuOpenedCVOptionsDivisor"/>
                    <Link to={"/blog"} className="menuOpenedCVOptionsLink"> BLOG </Link>
                    <div className="menuOpenedCVOptionsDivisor"/>
                    <Link to={"/projects"} className="menuOpenedCVOptionsLink"> PROJECTS </Link>
                    <div className="menuOpenedCVOptionsDivisor"/>
                    <Link to={"/games"} className="menuOpenedCVOptionsLink"> GAMES </Link>
                    <div className="menuOpenedCVOptionsDivisor"/>
                    <Link to={"/contact"} className="menuOpenedCVOptionsLink"> CONTACT </Link>
                </div>
            </div>

        )
    }

}

export default MenuOpenedCV;
