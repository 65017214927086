import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub, faLinkedinIn, faYoutube} from "@fortawesome/free-brands-svg-icons";

import './PhotoBigCV.css'

class PhotoBigCV extends React.Component{

    render() {
        return (
            <div>
                <div id="photoBigPhotoWrapper">
                    <img id="photoBigPhotoImage" src="pic2.png"/>
                </div>

                <div id="photoBigPersonalInfoWrapper">
                    <div className="photoBigPersonalInfoNameP"> Alex Valle </div>
                    <div className="photoBigPersonalInfoOccupationP"> Multimedia Engineer </div>
                    <div id="photoBigPersonalInfoSocialsWrapper">
                        <a href="https://www.linkedin.com/in/alexvalletavira/" target="_blank">
                            <FontAwesomeIcon className="photoBigPersonalInfoSocialIcon" icon={faLinkedinIn} />
                        </a>
                        <a href="https://github.com/alexvalletavira" target="_blank">
                            <FontAwesomeIcon className="photoBigPersonalInfoSocialIcon" icon={faGithub} />
                        </a>
                        <a href="https://www.youtube.com/channel/UCg9MhUz9W1Np-283i5uxx3g/featured" target="_blank">
                            <FontAwesomeIcon className="photoBigPersonalInfoSocialIcon" icon={faYoutube} />
                        </a>
                    </div>
                </div>
            </div>
        );
    }

}

export default PhotoBigCV;
