import React from 'react';
import Header from "../components/Header";
import SliderPictureSimple from "../components/SliderPictureSimple";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import GoUpFooter from "../components/GoUpFooter";

class Contact extends React.Component{

    renderHeader(){

        return(
            <Header
                info={[
                    ["HOME", false, "/"],
                    ["CV", false, "/cv"],
                    ["RENDERS", false, "/renders"],
                    ["BLOG", false, "/blog"],
                    ["PROJECTS", false, "/projects"],
                    ["GAMES", false, "/games"],
                    ["CONTACT", true, "/contact"]
                ]}
            />
        )

    }

    render(){

        return(
            <div>
                {this.renderHeader()}
                <SliderPictureSimple title = {"CONTACT"} image = {"react.png"}/>
                <ContactForm/>
                <GoUpFooter/>
                <Footer/>
            </div>
        )

    }

}

export default Contact;
