import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faBars, faGraduationCap, faBriefcase, faUser} from "@fortawesome/free-solid-svg-icons";
import './MenuLeftCV.css'

class MenuLeftCV extends React.Component{

    open1(){
        document.querySelector("#infoCV2Main").classList.add('closed');
        document.querySelector("#infoCV3Main").classList.add('closed');
        document.querySelector("#infoCV1Main").classList.remove('closed');
    }

    open2(){
        document.querySelector("#infoCV1Main").classList.add('closed');
        document.querySelector("#infoCV3Main").classList.add('closed');
        document.querySelector("#infoCV2Main").classList.remove('closed');
    }

    open3(){
        document.querySelector("#infoCV2Main").classList.add('closed');
        document.querySelector("#infoCV1Main").classList.add('closed');
        document.querySelector("#infoCV3Main").classList.remove('closed');
    }

    openMenu(){
        document.querySelector("#menuOpenedCVMain").classList.remove('closed');
    }

    render(){

        return(

            <div id="menuLeftCVMain">
                <div className="menuLeftCVYellowSquare">
                    <div className="menuLeftCVYellowSquare2">
                        <div>
                            <FontAwesomeIcon className="menuLeftCVYellowSquareIcon" icon={faBars} onClick={this.openMenu}/>
                        </div>
                    </div>
                </div>
                <div id="menuLeftCVSelectWrapper">
                    <div id="menuLeftCVSelectWrapper2">
                        <FontAwesomeIcon className="menuLeftCVSelectIcon" icon={faUser} onClick={this.open1}/>
                        <FontAwesomeIcon className="menuLeftCVSelectIcon" icon={faGraduationCap} onClick={this.open2}/>
                        <FontAwesomeIcon className="menuLeftCVSelectIcon" icon={faBriefcase} onClick={this.open3}/>
                    </div>
                </div>
                <div className="menuLeftCVYellowSquare">
                    <div className="menuLeftCVYellowSquare2">
                        <a href='CV_ALEX_VALLE.pdf' download><FontAwesomeIcon className="menuLeftCVYellowSquareIcon" icon={faDownload} /> </a>
                    </div>
                </div>
            </div>
        )

    }

}

export default MenuLeftCV;
