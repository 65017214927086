import React from 'react';
import './GoUpFooter.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";


class GoUpFooter extends React.Component{

    goUp(){
        window.scrollTo(0, 0);
    }

    render(){

        return(

            <div id="goUpFooterMain">

                <div className="footerGoUp">
                    <div className="buttonSociales">
                        <div className="buttonSociales2" onClick={this.goUp}>
                            <FontAwesomeIcon className="sliderIcono" icon={faArrowUp} />
                        </div>
                    </div>
                </div>

            </div>

        )
    }

}

export default GoUpFooter;
