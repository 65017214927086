import React from 'react';
import './ContactForm.css'
import './CoolButton.css'

class ContactForm extends React.Component{

    render(){
        return(
            <div id="contactMain">
                <div id="contactWrapper">
                    <div id="contactAnyQuestions">

                        <h2 id="contactH2">
                            GOT ANY QUESTIONS?
                        </h2>
                        <p id="contactP2">
                            Submit the following form if you want to contact me.
                        </p>

                    </div>

                    <div id="contactFill">
                        <form className="form" action="https://public.herotofu.com/v1/75f9e280-a5e4-11ec-975b-f9cc2b0e6900" method="post">
                            <p className="contactP">Your name:</p>
                            <div className="formPiece">
                                <input name="Name" id="nameForm" type="text" required/>
                            </div>
                            <p className="contactP">Your name:</p>
                            <div className="formPiece">
                                <input name="Email" id="emailForm" type="email" required/>
                            </div>
                            <p className="contactP">Your inquiry:</p>
                            <div className="formPiece">
                                <textarea name="Text" id="textForm" type="text" required/>
                            </div>
                            <div className="formPiece">
                                <input className="coolButtonWhite" type="submit" value="SUBMIT"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactForm;
