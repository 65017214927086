import React from 'react';
import './InfoCV3.css'

class InfoCV3 extends React.Component{

    render(){

        return(

            <div id="infoCV3Main">
                <div id="infoCV3Wrapper">
                    <div id="infoCV3WorkExp">
                        work experience
                    </div>
                    <div id="infoCV3InnerWrapper">
                        <div className="infoCV3WorksRow">
                            <div className="infoCV3WorksRowInside">
                                <p className="infoCV3PointTitle">Aigües de Barcelona</p>
                                <p className="infoCV3WorksText">1/9/2020 - 31/5/2021</p>
                                <p className="infoCV3WorksText">Barcelona</p>
                            </div>
                            <div className="infoCV3WorksRowInside">
                                <p className="infoCV3PointTitle">Tech division Intern</p>
                                <p className="infoCV3WorksText">•Burocratic and tech support towards the architecture team.</p>
                                <p className="infoCV3WorksText">•Team management and organization with BI technologies.</p>
                            </div>
                        </div>
                        <div className="infoCV3Separator"/>
                        <div className="infoCV3WorksRow">
                            <div className="infoCV3WorksRowInside">
                                <p className="infoCV3PointTitle">Èkratos Start-up</p>
                                <p className="infoCV3WorksText">20/9/2019 - 31/1/2021</p>
                                <p className="infoCV3WorksText">Barcelona</p>
                            </div>
                            <div className="infoCV3WorksRowInside">
                                <p className="infoCV3PointTitle">Front-End Intern</p>
                                <p className="infoCV3WorksText">•Development and design of the company's webpage using React.</p>
                                <p className="infoCV3WorksText">•Development and design of a blockchain voting app using React Native.</p>
                            </div>
                        </div>
                        <div className="infoCV3Separator"/>
                        <div className="infoCV3WorksRow">
                            <div className="infoCV3WorksRowInside">
                                <p className="infoCV3PointTitle">Gestión Tramos</p>
                                <p className="infoCV3WorksText">1/2/2018 - 30/4/2020</p>
                                <p className="infoCV3WorksText">Barcelona</p>
                            </div>
                            <div className="infoCV3WorksRowInside">
                                <p className="infoCV3PointTitle">Tech Support</p>
                                <p className="infoCV3WorksText">•Development and design of the company's webpage using Wordpress.</p>
                                <p className="infoCV3WorksText">•Development of a real state program using Microsoft Access.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        )

    }

}

export default InfoCV3;
