import React from 'react';
import './CV.css';
import MenuLeftCV from "../components/CV/MenuLeftCV";
import MenuOpenedCV from "../components/CV/MenuOpenedCV";
import PhotoBigCV from "../components/CV/PhotoBigCV";
import InfoCV1 from "../components/CV/InfoCV1";
import InfoCV2 from "../components/CV/InfoCV2";
import InfoCV3 from "../components/CV/InfoCV3";

export class CV extends React.Component{

    componentDidMount() {

        document.querySelector("#infoCV2Main").classList.add('closed');
        document.querySelector("#infoCV3Main").classList.add('closed');
        document.querySelector("#menuOpenedCVMain").classList.add('closed');
    }

    render(){

        return(
            <div id="mainCV">
                <div id="wrapperCV">

                    <MenuLeftCV/>
                    <MenuOpenedCV/>



                </div>

                <PhotoBigCV/>

                <InfoCV1/>

                <InfoCV2/>

                <InfoCV3/>

            </div>
        )

    }
}

export default CV;
