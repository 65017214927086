import React from 'react';
import './NewsRecap.css';
import NewsSmallArticle from "./NewsSmallArticle";
import news from '../data/news.js';
import {HashLink as Link} from "react-router-hash-link";

const arrayOf3 = Array.apply(null, Array(3));

class NewsRecap extends React.Component{

    renderNewsSmallArticle(header, subheader, text, id){

        return(
            <NewsSmallArticle key={"newsSmallArticle" + id}
                header = {header}
                subheader = {subheader}
                text = {text}
                id = {id}
            />
        )

    }

    render(){

        return(
            <div id="newsRecapMain">
                <div id="newsRecapWrapper">
                    <div>
                        <h1 id="newsRecapWrapperH1">
                            BLOG
                        </h1>
                        <h2 id="newsRecapWrapperH2">
                            What I've been up to?
                        </h2>
                    </div>
                    <div id="newsRecapLatest">
                        {
                            arrayOf3.map((array, index) => (
                                this.renderNewsSmallArticle(news[index].title, news[index].date, news[index].text, news[index].id)
                            ))
                        }
                    </div>
                    <Link to={"/blog"} className="link" onClick={() => window.scrollTo(0, 0)}>
                        <div className="coolButtonWhite">BLOG PAGE</div>
                    </Link>
                </div>
            </div>
        )

    }

}
export default NewsRecap;
