import React from 'react';
import './BlogNewsArticle.css'

class BlogNewsArticle extends React.Component{

    render(){

        return(

            <div className="newsArticleMain" id={"newsArticleId" + this.props.id}>
                <h1 className="blogNewsArticleH1">{this.props.title}</h1>
                <h2 className="blogNewsArticleH2">{this.props.date}</h2>
                <p className="blogNewsArticleP1">{this.props.text}</p>
                {
                    this.props.images.map((image, index) => (
                        <div className="blogNewsArticleImageWrapper" key={"wrp" + index}>
                            <img className="blogNewsArticleImage" key={"img" + index} src={image.toString()}/>
                        </div>
                    ))
                }
            </div>

        )

    }

}

export default BlogNewsArticle;
