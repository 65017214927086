import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import 'normalize.css'

import MainPage from "./pages/MainPage";
import Renders from "./pages/Renders";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import Games from "./pages/Games";
import CV from "./pages/CV";


ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<MainPage />}></Route>
              <Route path="/renders" element={<Renders/>}></Route>
              <Route path="/projects" element={<Projects/>}></Route>
              <Route path="/games" element={<Games/>}></Route>
              <Route path="/blog" element={<Blog/>}></Route>
              <Route path="/contact" element={<Contact/>}></Route>
              <Route path="/CV" element={<CV/>}></Route>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
