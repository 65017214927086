import React from 'react';
import './InfoCV2.css'

class InfoCV2 extends React.Component{

    render() {
        return(

            <div id="infoCV2Main">
                <div id="infoCV2Wrapper">
                    <div id="infoCV2MyEducation">
                        my education
                    </div>
                    <div>
                        <p className="infoCV2PointTitle">
                            2015 / 2021 - La Salle - Multimedia Engineering
                        </p>
                        <p className="infoCV2Text">
                            Finished my studies in Multimedia Engineering, a career that blends Computer Science with design. This is now the basis of all my programming and design skills and allowed me to further widen my knowledge on the related fields.
                        </p>

                        <div className="infoCV2Divisor"/>

                        <p className="infoCV2PointTitle">
                            2013 - Cambridge English: Advanced Certificate
                        </p>

                        <p className="infoCV2Text">
                            Got my certificate in advanced English. I have been practicing this language for more than 15 years now and I'm able to have native-like conversations with english speaker. Moving abroad to perfect my accent is one of my plans.
                        </p>

                        <div className="infoCV2Divisor"/>

                        <p className="infoCV2PointTitle">
                            2021 - EOI French: B1 Certificate
                        </p>

                        <p className="infoCV2Text">
                            Currently studying French, as learning languages is one of my hobbies. Planning on refining my skills, in french, to a similar level to my english.
                        </p>

                        <div className="infoCV2Divisor"/>
                    </div>

                    <div id="infoCV2LowerWrapper">

                        <div className="infoCV2Subtitle">
                            languages
                        </div>

                        <div className="infoCV2SkillsTable">
                            <div className="infoCV2SkillsRow">
                                <div className="infoCV2SkillsSquare">
                                    <p className="infoCV2LowerWrapperText">Spanish</p>
                                    <div className="infoCV2SkillBar100"/>
                                    <p className="infoCV2LowerWrapperText">Native</p>
                                </div>
                                <div className="infoCV2SkillsSquare">
                                    <p className="infoCV2LowerWrapperText">Catalan</p>
                                    <div className="infoCV2SkillBar100"/>
                                    <p className="infoCV2LowerWrapperText">Native</p>
                                </div>
                            </div>

                            <div className="infoCV2SkillsRow">
                                <div className="infoCV2SkillsSquare">
                                    <p className="infoCV2LowerWrapperText">English</p>
                                    <div className="infoCV2SkillBar100"/>
                                    <p className="infoCV2LowerWrapperText">100%</p>
                                </div>
                                <div className="infoCV2SkillsSquare">
                                    <p className="infoCV2LowerWrapperText">French</p>
                                    <div className="infoCV2SkillBar40Back">
                                        <div className="infoCV2SkillBar40">
                                        </div>
                                    </div>
                                    <p className="infoCV2LowerWrapperText">40%</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        )
    }

}

export default InfoCV2
