const news =

    [
        {
            "id": 3,
            "title": "WEBPAGE FINISHED",
            "date": "March 19, 2022",
            "text": "Finishing the last touches of the webpage. Baring a few minor things, this project is finished. Overall, pretty happy with how it turned out, it's a very versatile framework that allows me to easily update anything I want, all created by me from scratch! It also serves as a portfolio and as a place to show my CV around. I'll keep updating many things here, so stay tuned!",
            "images":[
            ]
        },

        {
            "id": 2,
            "title": "BLOG DONE!",
            "date": "March 16, 2022",
            "text": "I have finished the blog part of this website, the very section you're reading right now. To do so, I've included a .json file with all the news, so I can easily update this blog by adding new articles just by writing a few sentences in that file. So far so good, works really well and it's simple in design. By utilizing an anchorage link, I can link the news of the quick recap section of the main page with the blog page, allowing a fast travel to the news section. It also has image support, in case I want to put something cool. Let's hope all the news I update will be good ones!",
            "images":[
            ]
        },
        {
            "id": 1,
            "title": "RENDER SECTION DONE!",
            "date": "March 15, 2022",
            "text": "I've finalized creating the Renders section. As of now, you can see my 3 earliest works, a 3D chess set, a hallway and a bedroom. All of them have been modelled using 3DSMax and textured with Substance Painter. The render engine utilized was Arnold. Arnold is the standard ray-tracing engine that comes with Autodesk and it is very effective while rendering both interiors and exteriors. Thanks to Substance Painter, I can add more than the Albedo map to my textures, making the materials used in the scene look realistic.",
            "images":[
                ]
        },
    ]

export default news;
