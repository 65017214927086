import React from 'react';
import './NewsSmallArticle.css'
import { HashLink as Link } from 'react-router-hash-link';

class NewsSmallArticle extends React.Component{

    render(){
        return(
            <div id="newsSmallArticleMain">
                <h1 className="newsSmallArticleH1">
                    {this.props.header}
                </h1>
                <h2 className="newsSmallArticleH2">
                    {this.props.subheader}
                </h2>
                <p className="newsSmallArticleP1">
                    {this.props.text}
                </p>
                <p className="newsSmallArticleP2">
                    <Link to={ ("/blog#newsArticleId" + this.props.id.toString()).toString()} className="link"> Read more </Link>
                </p>
            </div>
        )
    }


}

export default NewsSmallArticle;
