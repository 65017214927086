import React from 'react';
import './Footer.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub, faLinkedinIn, faYoutube} from "@fortawesome/free-brands-svg-icons"


class Footer extends React.Component{

    render(){

        return(

            <div id="footerMain">

                <div id="footerWrapper">

                    <div id="footerText">
                        Website design by <b id="footerBold">Alex Valle</b>, 2022.
                    </div>

                    <div id="footerSocials">
                        <div className="buttonSociales">
                            <a href="https://www.linkedin.com/in/alexvalletavira/" target="_blank">
                                <div className="buttonSociales2">
                                    <FontAwesomeIcon className="sliderIcono" icon={faLinkedinIn} />
                                </div>
                            </a>
                        </div>
                        <div className="buttonSociales">
                            <a href="https://github.com/alexvalletavira" target="_blank">
                                <div className="buttonSociales2">
                                    <FontAwesomeIcon className="sliderIcono" icon={faGithub} />
                                </div>
                            </a>
                        </div>
                        <div className="buttonSociales">
                            <a href="https://www.youtube.com/channel/UCg9MhUz9W1Np-283i5uxx3g/featured" target="_blank">
                                <div className="buttonSociales2">
                                    <FontAwesomeIcon className="sliderIcono" icon={faYoutube} />
                                </div>
                            </a>
                        </div>
                    </div>

                </div>

            </div>

        )
    }

}

export default Footer;
