import React from 'react';
import Header from "../components/Header";
import SliderPictureSimple from "../components/SliderPictureSimple";
import SlideshowCustom from "../components/SlideshowCustom";
import Footer from "../components/Footer";
import GoUpFooter from "../components/GoUpFooter";


class Renders extends React.Component{

    renderHeader(){

        return(
            <Header
                info={[
                    ["HOME", false, "/"],
                    ["CV", false, "/cv"],
                    ["RENDERS", true, "/renders"],
                    ["BLOG", false, "/blog"],
                    ["PROJECTS", false, "/projects"],
                    ["GAMES", false, "/games"],
                    ["CONTACT", false, "/contact"]
                ]}
            />
        )

    }

    renderCustomSlideshow(name, text, image, number, link, linkText){

        return(
            <SlideshowCustom
                name = {name}
                text = {text}
                image = {image}
                number = {number}
                link = {link}
                linkText = {linkText}
            />
        )

    }

    render() {

        return(
            <div>
                {this.renderHeader()}
                <SliderPictureSimple title = {"RENDERS"} image = {"renders/chess3.png"}/>
                <div className="slideEmpty"/>
                {this.renderCustomSlideshow(
                    "CHESS",
                    "A 3D chess, whose main characteristic is that when viewed from the top, the pieces have the shape of a normal 2D chess symbol. This is done through clever modelling, emptying the areas needed to transform the 3D model into a 2D icon.",
                    "chess",
                    9,
                    null,
                    null
                )}
                {this.renderCustomSlideshow(
                    "BEDROOM",
                    "A render of a bedroom, with a cozy ambient. The bed and the wooden furniture give a homely vibe, while the warmth of the fireplace contrasts with the chill from outside, which can be viewed through the frosted windows.",
                    "bedroom",
                    3,
                    null,
                    null
                )}
                {this.renderCustomSlideshow(
                    "HALLWAY",
                    "A simple hallway, the first 3D render I've done utilizing Arnold. In this render the main focus is reflection, as there are many mirrors, which project the calid summer sunlight to the entire scene.",
                    "pasillo",
                    2,
                    null,
                    null
                )}
                <GoUpFooter/>
                <Footer/>
            </div>
        )

    }

}

export default Renders;
