import React from 'react';
import './HeaderButton.css';
import {Link} from "react-router-dom";


class HeaderButton extends React.Component{

    render(){
        if(this.props.selected === false) {
            return (
                <div className="buttonHeader">
                    <Link to={this.props.link} className="link"> {this.props.text} </Link>
                </div>
            )
        }else{
            return (
                <div className="buttonHeaderSelected">
                    <Link to={this.props.link} className="link"> {this.props.text} </Link>
                </div>
            )
        }
    }

}



export default HeaderButton;
