import React from 'react';
import SlideshowCustom from "../components/SlideshowCustom";
import Header from "../components/Header";
import SliderPictureSimple from "../components/SliderPictureSimple";
import GoUpFooter from "../components/GoUpFooter";
import Footer from "../components/Footer";


class Projects extends React.Component{

    renderHeader(){

        return(
            <Header
                info={[
                    ["HOME", false, "/"],
                    ["CV", false, "/cv"],
                    ["RENDERS", false, "/renders"],
                    ["BLOG", false, "/blog"],
                    ["PROJECTS", true, "/projects"],
                    ["GAMES", false, "/games"],
                    ["CONTACT", false, "/contact"]
                ]}
            />
        )

    }

    renderCustomSlideshow(name, text, image, number, link, linkText){

        return(
            <SlideshowCustom
                name = {name}
                text = {text}
                image = {image}
                number = {number}
                link = {link}
                linkText = {linkText}
            />
        )

    }
    render(){

        return(

            <div>
                {this.renderHeader()}
                <SliderPictureSimple title = {"PROJECTS"} image = {"renders/path5.png"}/>
                <div className="slideEmpty"/>
                {this.renderCustomSlideshow(
                    "PATHFINDING ALGORITHM SHOWCASE",
                    "An interactive display of different pathfinding algorithms. In this project, you can select the starting point and the ending point of a route, add some obstacles and finally choose which algorithm you want to see. When the code is executed, a route is shown, depending on the settings you've introduced. This project was made utilizing HTML, CSS, Javascript and Jquery.",
                    "path",
                    7,
                    "https://modest-hamilton-f99d77.netlify.app/",
                    "CHECK IT OUT ONLINE"
                )}

                <div className="slideEmpty"/>
                {this.renderCustomSlideshow(
                    "SORTING ALGORITHM SHOWCASE",
                    "A recap of some of the most common sorting algorithms. This piece of code creates random arrays with random sizes and allows you to select which algorithm you want to use for ordering them. You can adjust the speed of the sorting animations and request a random new array when needed.",
                    "sorting",
                    4,
                    "https://amazing-wozniak-f4ab88.netlify.app/",
                    "CHECK IT OUT ONLINE"
                )}
                <div className="slideEmpty"/>
                <GoUpFooter/>
                <Footer/>
            </div>

        )

    }

}

export default Projects;
