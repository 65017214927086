import React from 'react';

class SliderPictureSimple extends React.Component{

    render(){
        return(
            <div id="mainSliderPicture">
                <img id="sliderPictureSimpleImage" src={this.props.image.toString()}/>
                <div id="sliderPictureSimpleWrapper">
                    <div id="sliderPictureSimpleText">
                        {this.props.title}
                    </div>
                </div>
            </div>
        )
    }
}

export default SliderPictureSimple;
