import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import './SlideshowCustom.css'

const properties = {
    duration: 3000,
    transitionDuration: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    indicators: true,

};


class SlideshowCustom extends React.Component{

    renderLinkBox(link, text){

        return(
            <a href={link} target="_blank">
                <div className="slideshowCustomLinkMain">
                    <div className="slideshowCustomLinkWrapper">
                        <div className="slideshowCustomLinkLink">
                            {text}
                        </div>
                    </div>
                </div>
            </a>
        )

    }

    render(){

        let array = Array.apply(null, Array(this.props.number));

        return(

            <div className="slideshowMain">

                <div className="slideshowWrapper">
                    <div className="slideshowTextWrapper">
                        <div className="slideshowText">
                            {this.props.name}
                        </div>
                        <div className="slideshowExplained">
                            {this.props.text}
                        </div>
                    </div>
                    <div className="slideshowCustomEmptySpace"/>
                    <Fade  {...properties}>
                        {array.map((image, index) => (
                            <div key = {"each " + index} className="each-fade">
                                <div key = {"div " + index}>
                                    <img key={"slide " + index} src={"renders/" + this.props.image + (index + 1) + ".png"} />
                                </div>
                            </div>
                        ))}
                    </Fade>
                    {this.props.link != null && this.renderLinkBox(this.props.link, this.props.linkText)}
                </div>

            </div>

        )
    }

}

export default SlideshowCustom;
