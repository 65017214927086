import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDesktop, faGear, faPenRuler} from "@fortawesome/free-solid-svg-icons";
import {faUnity} from "@fortawesome/free-brands-svg-icons";
import './InfoCV1.css'

class infoCV1 extends React.Component{

    render() {
        return(
            <div id="infoCV1Main">
                <div id="infoCV1Wrapper">
                    <div id="infoCV1AboutMe">
                        about me
                    </div>
                    <div id="infoCV1AgePlaceOccupation">
                        <p className="infoCV1AgePlaceOccupationText">25 years</p>
                        <p className="infoCV1AgePlaceOccupationBars">/</p>
                        <p className="infoCV1AgePlaceOccupationText">Barcelona</p>
                        <p className="infoCV1AgePlaceOccupationBars">/</p>
                        <p className="infoCV1AgePlaceOccupationText">Multimedia Engineer</p>
                    </div>
                    <div>
                        <p className="infoCV1Summary">

                            Experienced in design and development of virtual projects both individually and in team environments. Proactive, cooperative, diligent, responsible and enthusiastic person.

                            Most proficient at Front-end web developing, 3D modelling and Unity.

                            Currently my goal is to develop my professional career and grow in the field of engineering.
                        </p>
                    </div>

                    <div className="infoCV1Divisor">dummy</div>

                    <p className="infoCV1Subtitle">my skills</p>

                    <div className="infoCV1SkillsTable">
                        <div className="infoCV1SkillsRow">
                            <div className="infoCV1SkillsSquare">
                                <FontAwesomeIcon className="infoCV1SkillsSquareIcon" icon={faDesktop} inverse />
                                <p className="infoCV1SkillsSquareTitle">
                                    front end development
                                </p>
                                <p className="infoCV1SkillsSquareText">
                                    Experience in programming in HTML, CSS, Javascript, Jquery, React and React Native.
                                </p>
                            </div>
                            <div className="infoCV1SkillsSquare">
                                <FontAwesomeIcon className="infoCV1SkillsSquareIcon" icon={faGear} inverse />
                                <p className="infoCV1SkillsSquareTitle">
                                    algorithm programming
                                </p>
                                <p className="infoCV1SkillsSquareText">
                                    Skills in programming complex algorithms in a wide array of diverse programming languages, such as C, C++, Java and PHP.
                                </p>
                            </div>
                        </div>
                        <div className="infoCV1SkillsRow">
                            <div className="infoCV1SkillsSquare">
                                <FontAwesomeIcon className="infoCV1SkillsSquareIcon" icon={faUnity} inverse />
                                <p className="infoCV1SkillsSquareTitle">
                                    front end development
                                </p>
                                <p className="infoCV1SkillsSquareText">
                                    Background at creating 3D scenes, for rendering, real time usage and VR, using 3DS Max, Unity and Substance.
                                </p>
                            </div>
                            <div className="infoCV1SkillsSquare">
                                <FontAwesomeIcon className="infoCV1SkillsSquareIcon" icon={faPenRuler} inverse />
                                <p className="infoCV1SkillsSquareTitle">
                                    design
                                </p>
                                <p className="infoCV1SkillsSquareText">
                                    Proficient at designing in 2D spaces, be it for web development, icons or other tasks.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default infoCV1;
