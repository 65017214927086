import React from "react";
import './SnapshotMainPage.css'
import './CoolButton.css'
import {HashLink as Link} from "react-router-hash-link";

class SnapshotMainPage extends React.Component{

    render(){

        var divStyle = {
            backgroundImage: 'url(' + this.props.image + ')',
            height: '300px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        }

        return(


            <div style={divStyle}>
                <div className="rendersWrapper">
                    <h1 className="rendersH1">{this.props.header}</h1>
                    <h2 className="rendersH2">{this.props.text}</h2>
                    <Link to={ this.props.link} className="link" onClick={() => window.scrollTo(0, 0)}>
                        <div className="coolButtonYellow">{this.props.button}</div>
                    </Link>
                </div>
            </div>

        )
    }

}

export default SnapshotMainPage;
